import fetch from "cross-fetch";

const ENDPOINT = "https://api.pancakeswap.info/api/v2/pairs/";
const API = "https://api.example.com/lpInfo"

interface Pair {
  pair_address: string;
  base_name: string;
  base_symbol: string;
  base_address: string;
  quote_name: string;
  quote_symbol: string;
  quote_address: string;
  price: string;
  base_volume: string;
  quote_volume: string;
  liquidity: string;
  liquidity_BNB: string;
}

const wBnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";

const jitAddress = "0x42539f50c5f8a0c929e7895eb265391f58b22a19";
const lpAddress = "0xe55072ab00eeccd4ed5b581849e578fd54585ff8";

const url = `${API}/${wBnbAddress}/${jitAddress}/${lpAddress}`
console.log('xx', url)

export async function fetchTotalLiquidity(): Promise<number> {
  try {
    const res = await fetch(url);
    const jsonData:  Pair = await res.json();
    // const pair = jsonData.data[pair  Address];
    console.log('xx', jsonData)
    return parseFloat(jsonData.liquidity);
    // if (pair) {
    //   return parseFloat(pair.liquidity);
    // }
    // return 0;
  } catch (error) {
    console.log(error);
    return 0;
  }
}
