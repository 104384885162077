import { useUpdateAtom } from "jotai/utils";
import { Button, ButtonProps } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { openAtom } from "@atoms/wallet";

interface Props extends ButtonProps {
  rounded?: boolean;
}

const StyledButton = withStyles(({ palette }) => ({
  root: {
    fontWeight: 700,
    borderRadius: 3,
    color: "#fff",
  },
}))(Button);
 
const useStyles = makeStyles({
  rounded: {
    boxShadow: "0 2px 6px 0 #BDBDBD",
    letterSpacing: "0.88px",
    backgroundColor: '#35e39d',
    borderRadius: '3px',
    fontWeight:700,
    textTransform: "capitalize",
    display: 'inline-block',
    "&:hover": {
      boxShadow: "0 2px 6px 0 #BDBDBD",
      background: '#35e39d',
    },
    "&:disabled": {
      color: "#fff",
      background: '#a2f6c5',
      boxShadow: "none",
      cursor: "not-allowed !important",
    },
  },
});

const isAvailable = true;

export default function ConnectButton({ rounded, ...props }: Props) {
  const classes = useStyles();

  const setOpen = useUpdateAtom(openAtom);

  return (
    <StyledButton
      variant='contained'
      color='primary'
      size='small'
      onClick={() => setOpen(true)}
      disabled={!isAvailable}
      {...props}
      className={rounded ? classes.rounded : ""}
    >
    {isAvailable ? "Connect Wallet" : "Available Soon"}
    </StyledButton>
  );
}
