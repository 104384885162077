import { useCallback } from "react";
import { constants } from "ethers";
import { useUpdateAtom } from "jotai/utils";
import { useSnackbar } from "notistack";
import { loadingAllAtom } from "@atoms/loading";
import { availableAtomFamily } from "@atoms/balance";
import { Token } from "@utils/constants";
import {
  // usePHB,
  useJIT,
  useLP,
  // useDeprecatedLP,
  // useLegacyLP,
} from "./useContract";
import useWallet from "./useWallet";
import useFetchStakingData from "./useFetchStakingData";

export default function useFetchState() {
  const { account } = useWallet();

  const { enqueueSnackbar } = useSnackbar();

  // token available
  // const phbToken = usePHB();
  const JITToken = useJIT();
  const lpToken = useLP();
  // const deprecatedLpToken = useDeprecatedLP();
  // const legacyLpToken = useLegacyLP();

  // all loading
  const setLoading = useUpdateAtom(loadingAllAtom);

  // available atoms
  // const setAvailablePHB = useUpdateAtom(availableAtomFamily(Token.PHB));
  const setAvailableJIT = useUpdateAtom(availableAtomFamily(Token.JIT));
  const setAvailableLP = useUpdateAtom(availableAtomFamily(Token.JIT_BNB_LP));
  // const setAvailableDeprecatedLP = useUpdateAtom(
  //   availableAtomFamily(Token.JIT_BNB_LP_DEPRECATED)
  // );
  // const setAvailableLegacyLP = useUpdateAtom(
  //   availableAtomFamily(Token.JIT_BNB_LP_LEGACY)
  // );

  // fetch token staking data
  // const fetchPHBStakingData = useFetchStakingData(Token.PHB);
  const fetchJITStakingData = useFetchStakingData(Token.JIT);
  const fetchLPStakingData = useFetchStakingData(Token.JIT_BNB_LP);
  // const fetchDeprecatedLPStakingData = useFetchStakingData(
  //   Token.JIT_BNB_LP_DEPRECATED
  // );
  // const fetchLegacyLPStakingData = useFetchStakingData(Token.JIT_BNB_LP_LEGACY);

  const fetchBalances = useCallback(async () => {
    try {
      setLoading(true);
      const [JIT, lp] = await Promise.all([ //phb, deprecatedLp, legacyLp
        // account && phbToken ? phbToken.balanceOf(account) : constants.Zero,
        account && JITToken
          ? JITToken.balanceOf(account)
          : constants.Zero,
        account && lpToken ? lpToken.balanceOf(account) : constants.Zero,
        // account && deprecatedLpToken
        //   ? deprecatedLpToken.balanceOf(account)
        //   : constants.Zero,
        // account && legacyLpToken
        //   ? legacyLpToken.balanceOf(account)
        //   : constants.Zero,
        // fetchPHBStakingData(),
        fetchJITStakingData(),
        fetchLPStakingData(),
        // fetchDeprecatedLPStakingData(),
        // fetchLegacyLPStakingData(),
      ]);

      // setAvailablePHB(phb);
      setAvailableJIT(JIT);
      setAvailableLP(lp);
      // setAvailableDeprecatedLP(deprecatedLp);
      // setAvailableLegacyLP(legacyLp);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to loading balances", { variant: "error" });
    }
    setLoading(false);
  }, [
    setLoading,
    account,
    // phbToken,
    JITToken,
    lpToken,
    // deprecatedLpToken,
    // legacyLpToken,
    // fetchPHBStakingData,
    fetchJITStakingData,
    fetchLPStakingData,
    // fetchDeprecatedLPStakingData,
    // fetchLegacyLPStakingData,
    // setAvailablePHB,
    setAvailableJIT,
    setAvailableLP,
    // setAvailableDeprecatedLP,
    // setAvailableLegacyLP,
    enqueueSnackbar,
  ]);

  return fetchBalances;
}
