import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRequest from "@ahooksjs/use-request";
import StakeCard, { StakeCardProps } from "@components/StakeCard";
import { Token, TOKEN_ADDRESS, Action } from "@utils/constants";
import useWallet from "@hooks/useWallet";
import useFetchStats from "@hooks/useFetchStats";
import useFetchState from "@hooks/useFetchState";
import JITBg from "@assets/bgs/pswap.png";
import cakeLogo from "@assets/tokens/cake.png";

const useStyles = makeStyles({
  container: {
    padding: "6vh 24px 24px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  card: {
    margin: 10,
  },
});

const cards: StakeCardProps[] = [
  // {
  //   token: Token.PHB,
  //   bg: phbBg,
  //   color: "#FC4C07",
  //   logo: phbLogo,
  //   links: [
  //     {
  //       href: "https://www.binance.com/en/trade/PHB_BTC",
  //       logo: bnbLogo,
  //       text: "Buy PHB",
  //     },
  //   ],
  //   desc: (
  //     <>
  //       Stake BEP-20 PHB to earn JIT. <br />
  //       To convert your existing PHX or BEP-2 PHB to BEP-20 PHB, click{" "}
  //       <Link
  //         href='https://horizonprotocol.medium.com/swap-guide-phx-nep5-phb-bep2-and-phb-bep20-f79c0d12135c'
  //         target='_blank'
  //       >
  //         here
  //       </Link>
  //       .
  //     </>
  //   ),
  // },
  {
    token: Token.JIT,
    bg: JITBg,
    color: "#fff",
    desc: (
      <>
        Stake BEP-20 SPEP to earn SPEP. <br />
        You can buy on Pancakeswap to get SPEP tokens.
      </>
    ),
    // links: [
    //   {
    //     href: `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${
    //       TOKEN_ADDRESS[56][Token.JIT]
    //     }`,
    //     logo: cakeLogo,
    //     text: "Get SPEP Tokens",
    //   },
    // ],
  },
  // {
  //   token: Token.JIT_BNB_LP,
  //   bg: bnbBg,
  //   color: "#fff",
  //   // open: false,
  //   // disabledActions: [Action.Stake],
  //   desc: (
  //     <>
  //       Stake SPEP-BNB LPs to earn SPEP. <br />
  //       You can provide liquidity on Pancakeswap to get SPEP-BNB LP tokens.
  //     </>
  //   ),
  //   links: [
  //     {
  //       href: `https://exchange.pancakeswap.finance/#/add/BNB/${
  //         TOKEN_ADDRESS[56][Token.JIT]
  //       }`,
  //       logo: cakeLogo,
  //       text: "Get SPEP-BNB LP Tokens",
  //     },
  //   ],
  // },
  // {
  //   token: Token.JIT_BNB_LP_DEPRECATED,
  //   bg: bnbBg,
  //   color: "#FF325F",
  //   // open: false,
  //   cardTitle: "Please Unstake",
  //   disabledActions: [Action.Stake],
  //   desc: (
  //     <>
  //       This pool is disabled, please unstake immediately. To transfer your
  //       discontinued LP to the updated LP, click here.
  //     </>
  //   ),
  //   links: [
  //     {
  //       href:
  //         "https://pancakeswap.medium.com/the-great-migration-vote-4093cb3edf23",
  //       logo: cakeLogo,
  //       text: "Pancakeswap v2 Migration",
  //     },
  //     // {
  //     //   href: `https://exchange.pancakeswap.finance/#/add/BNB/${
  //     //     TOKEN_ADDRESS[56][Token.JIT]
  //     //   }`,
  //     //   logo: cakeLogo,
  //     //   text: "GET JIT-BNB LP TOKENS",
  //     // },
  //   ],
  // },
  // {
  //   token: Token.JIT_BNB_LP_LEGACY,
  //   bg: bnbBg,
  //   color: "#FF325F",
  //   cardTitle: "Please Unstake",
  //   disabledActions: [Action.Stake],
  //   desc: (
  //     <>
  //       Due to the Pancakeswap migration, this pool is no longer active. Please
  //       unstake your tokens and go through the LP migration process{" "}
  //       <Link
  //         href='https://v1exchange.pancakeswap.finance/#/migrate'
  //         target='_blank'
  //       >
  //         here
  //       </Link>
  //       .
  //     </>
  //   ),
  //   links: [
  //     {
  //       href:
  //         "https://pancakeswap.medium.com/the-great-migration-vote-4093cb3edf23",
  //       logo: cakeLogo,
  //       text: "Pancakeswap v2 Migration",
  //     },
  //   ],
  // },
];

export default function Home() {
  const classes = useStyles();

  const { connected } = useWallet();

  const fetchStats = useFetchStats();
  const fetchState = useFetchState();

  useRequest(fetchStats, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
  });

  const { run, cancel } = useRequest(fetchState, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
  });

  useEffect(() => {
    if (connected) {
      run();
    } else {
      cancel();
    }
  }, [cancel, connected, run]);

  return (
    <div className={classes.container}>
      <img src="stake.svg" width="36%"/>
      {cards.map((card) => (
        <StakeCard key={card.token} {...card} className={classes.card} />
      ))}
    </div>
  );
}
