/** @format */

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardProps,
  CardHeader,
  CardActions,
  CardContent,
} from "@material-ui/core";
import { useAtomValue } from "jotai/utils";
import { stakedAtomFamily, withdrawableAtomFamily } from "@atoms/balance";
import defaultTheme from "@utils/theme";
import useWallet from "@hooks/useWallet";
import ExternalLink from "@components/ExternalLink";
import ConnectButton from "../ConnectButton";
import CardSection from "./CardSection";
import Pending from "./Pending";
import Stats from "./Stats";
import Earned from "./Earned";
import AmountStake from "./AmountStake";
import React, { value, useMemo } from "react";
import { DEPRECATED_TOKENS } from "@/utils/constants";
import { BsMedium } from "react-icons/bs";

import {
  FaTwitter,
  FaTelegram,
  FaDiscord,
  FaReddit,
  FaGithub,
  FaInstagram,
} from "react-icons/fa";

const useStyles = makeStyles(() => ({
  desc: {
    color: "#4f5b6d",
    fontSize: "14px",
    lineHeight: "22px",
    minHeight: 22 * 3,
  },
}));

const StyledCard = withStyles(({ palette }) => ({
  root: {
    width: 340,
    flex: "0 0 340px",
    borderRadius: 3,
    color: "#000 !important",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 180px",
    backgroundPosition: "top -12px right -12px",
    // border: `1px solid ${palette.divider}`,
  },
}))(Card);

const StyledHeader = withStyles({
  root: {
    paddingTop: 32,
    color: "#000 !important",
    backgroundColor: "#fff",
  },
  title: {
    marginBottom: 8,
    fontFamily: "Roboto Condensed",
    letterSpacing: "3px",
    textTransform: "uppercase",
  },
})(CardHeader);

const StyledContent = withStyles(() => ({
  root: {
    padding: 0,
    backgroundColor: "#85f6b4ac", //rgba(12,17,29,0.5)
  },
}))(CardContent);

const StyledLinks = withStyles(({ palette }) => ({
  root: {
    padding: 16,
    backgroundColor: "#fff", //rgba(28,57,95,0.25)
    // borderTop: `1px solid ${palette.divider}`,
  },
}))(CardActions);

interface LinkProps {
  href: string;
  logo: string;
  text: string;
}

export interface StakeCardProps extends CardProps {
  token: TokenEnum;
  cardTitle?: string | React.ReactNode;
  desc: string | React.ReactNode;
  bg: string;
  color?: string;
  logo?: string;
  links?: LinkProps[];
  open?: boolean;
  disabledActions?: ActionEnum[];
}

export default function StakeCard({
  token,
  bg,
  color = defaultTheme.palette.primary.main,
  cardTitle,
  desc,
  logo,
  links,
  open = true,
  disabledActions,
  ...props
}: StakeCardProps) {
  const classes = useStyles();
  const { connected } = useWallet();

  const staked = useAtomValue(stakedAtomFamily(token));
  const withdrawable = useAtomValue(withdrawableAtomFamily(token));

  const cardEnabled = useMemo(() => {
    // if (DEPRECATED_TOKENS.indexOf(token) > -1) {
    //   return !staked.isZero() || !withdrawable.isZero();
    // }
    return true;
  }, [staked, token, withdrawable]);

  if (!cardEnabled) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          color: "#000",
          textAlign: "center",
        }}>
        {/* <a target="_BLANK" href="https://app.unicrypt.network/amm/pancake-v2/ilo/0xa441565e748267216aE67dBC0639dF00E7a43aBD">
          <img src="uni.png" width="12%"  />
        </a> */}
        <div className='footer-social'>
          <ul className='social-list'>
          <li>
                    <a
                      rel='noreferrer'
                      target='_BLANK'
                      href='https://secretpep.medium.com/'>
                      <BsMedium />
                    </a>
                  </li>
            <li>
              <a
                target='_BLANK'
                rel='noreferrer'
                href='https://twitter.com/SecretPep_'>
                <FaTwitter />
              </a>
            </li>
            <li>
              <a
                target='_BLANK'
                rel='noreferrer'
                href='https://t.me/Secret_pep'>
                <FaTelegram />{" "}
              </a>
            </li>
            <li>
              <a
                target='_BLANK'
                href='https://discord.gg/SUnBNkhvRc'
                rel='noreferrer'>
                <FaDiscord />
              </a>
            </li>
            <li>
              <a
                target='_BLANK'
                href='https://www.reddit.com/r/Secret_Pep'
                rel='noreferrer'>
                <FaReddit />
              </a>
            </li>
            <li>
              <a
                target='_BLANK'
                href='https://github.com/secretpep'
                rel='noreferrer'>
                <FaGithub />
              </a>
            </li>
            <li>
              <a
                target='_BLANK'
                href='https://www.instagram.com/secretpep_/'
                rel='noreferrer'>
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <StyledCard
        // variant='outlined'
        // style={{
        //   backgroundImage: `url(${bg})`,
        //   backgroundColor: '#113f65',
        //   boxShadow: '0 8px 40px 0 rgba(0,0,0,10%)',
        // }}
        {...props}>
        <StyledHeader
          title={cardTitle || `Stake ${token}`}
          subheader={
            <Typography className={classes.desc} color='textPrimary'>
              {desc}
            </Typography>
          }
          style={{
            color,
          }}
        />
        <StyledContent>
          <Stats token={token} />
        </StyledContent>
        <div
          style={{
            position: open ? undefined : "relative",
          }}>
          <StyledContent>
            <Earned token={token} />
            {connected ? (
              <AmountStake
                logo={logo}
                token={token}
                disabledActions={disabledActions}
              />
            ) : (
              <CardSection>
                <ConnectButton fullWidth rounded size='large' />
              </CardSection>
            )}
          </StyledContent>
          <StyledLinks>
            {links?.map(({ href, logo, text }) => (
              <ExternalLink key={href} href={href} logo={logo}>
                {text}
              </ExternalLink>
            ))}
          </StyledLinks>
          {!open && <Pending>pending</Pending>}
        </div>
      </StyledCard>
    </React.Fragment>
  );
}
