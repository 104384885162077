import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledButton = withStyles(({ palette }) => ({
  root: {
    flex: "0 0 120px",
    color: '#fff',//palette.text.primary,
    boxShadow: "0 2px 12px 0 #BDBDBD",
    backgroundColor: '#35e39d',
    textTransform: "capitalize",
    borderRadius: '5px',
    fontWeight: 700,
    display: 'inline-block',
    "&:hover": {
      background: '#35e39d',
    },
    "&:disabled": {
      color: "#fff",
      background: '#a2f6c5',
      boxShadow: "none",
      cursor: "not-allowed !important",
    },
  },
}))(Button);

interface Props extends ButtonProps {
  loading?: boolean;
}

export default function PrimaryButton({
  loading = false,
  disabled,
  children,
  ...props
}: Props) {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {loading ? <CircularProgress color="inherit" size={24} thickness={2} /> : children}
    </StyledButton>
  );
}
